<template>
      <v-app class>
        <div>
          <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex ">
                <div class="col-md-11">
                  <h1>Exit</h1>
                </div>
              </div>

              <div class="pa-5 d-flex">
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-row no-gutters>
                    <v-col cols="6 d-flex mt-auto mb-auto">
                    <v-select v-model="action" class="mr-5 action-select" :items="actions" item-value="id" item-text="name" :disabled="selected.length === 0"   label="Actions" return-object outlined dense/>
                  </v-col>
                  <v-col cols="6 d-flex mt-auto mb-auto">
                    <div class="submit-button-container">
                      <Button
                      :label="'Submit'"
                      :btnType="'Submit'"
                      :disabled="action === '' || loading"
                      @onClick="approveBulkSelection"
                      :isLoading="loading"
                      class=""
                    />
                    </div>
                  </v-col>
                  </v-row>
                  </v-col>
                </v-row>
              </div>
             <v-data-table
                :headers= 'headers'
                :items="exitBenefits"
                show-select
                :single-select="false"
                v-model="selected"
             >
                <template v-slot:item.request.dateAdded="{ item }" >
                  {{item.request.dateAdded | formatDate}}
                </template>
                <template v-slot:item.actions="{ item }" >
                  <div class="d-flex">
                    <Button :btnType="'Submit'" :label="'Approve'" @onClick="openConfirmDialog(item)" class="mr-2"/>
                    <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openRejectModal(item)" class="mr-2"/>
                  </div>
                </template>

             </v-data-table>
            <!-- reject dialog  -->
              <v-dialog v-model="rejectDialog" persistent max-width="400">
                <v-card>
                  <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
                  <v-divider class="mx-4 mb-5"></v-divider>
                  <v-card-text class="mt-5">
                    <v-form ref="commentForm" v-model="valid">
                      <TextField  v-model="formData.comments" :label="'Comment'"/>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <Button
                      :label="'Reject'"
                      :btnType="'Submit'"
                      @onClick="reject"
                      :isLoading="rejectLoading"
                      :disabled="rejectLoading"
                      class="mr-4"
                    />
                    <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </v-container>
          </div>
        </div>
        <ConfirmationDialog :title="'Are you sure you want to approve?'" :isLoading="approveLoading"  :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve"/>
      </v-app>
</template>
<script>
// import moment from 'moment'
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { benefitService } from '@/services'
// import { FETCH_LEAVE } from '@/store/action-type'
export default {
  components: {
    Button,
    TextField,
    ConfirmationDialog
  },
  data () {
    return {
      search: '',
      isLoading: false,
      loading: false,
      approveLoading: false,
      rejectLoading: false,
      dialog: false,
      action: '',
      formData: {
        id: '',
        startDate: '',
        days: '',
        resumptionDate: '',
        comments: '',
        leaveType: null,
        EmployeeId: ''
      },
      rejectDialog: false,
      leave: null,
      valid: false,
      item: null,
      isSaving: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      exitBenefits: [],
      selected: [],
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }],
      headers: [
        {
          text: 'First Name',
          value: 'employee.firstName'
        },
        {
          text: 'Last Name',
          value: 'employee.lastName'
        },
        {
          text: 'Exit Reason',
          value: 'exitReason.name'
        },
        {
          text: 'Benefits Liable To Tax',
          value: 'benefitsLiableToTax'
        },
        {
          text: 'Paye',
          value: 'paye'
        },
        {
          text: 'Relief',
          value: 'relief'
        },
        {
          text: 'Taxable Income',
          value: 'taxableIncome'
        },
        {
          text: 'Vat',
          value: 'vat'
        },
        // {
        //   text: 'Comment',
        //   value: 'comment'
        // },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    closeLeaveModal () {
      this.$refs.leaveViewModal.closeModal()
    },
    openConfirmDialog (item) {
      this.dialog = true
      this.item = item
    },
    approveBulkSelection () {
      this.loading = true
      if (this.action.id === 2 && this.formData.comments === '') {
        this.rejectDialog = true
        this.bulk = true
        return
      }

      let bulkItems = []

      this.selected.forEach(element => {
        bulkItems.push({ 'id': parseInt(element.id), Status: this.action.id, Comments: this.action.id === 1 ? 'Approved' : this.formData.comments })
      })

      this.rejectDialog = false

      benefitService.approveTerminalBenefits(bulkItems).then(result => {
        this.showAlertMessage(`${this.action.name}  Exit Benefit successful`, 'success')
      }).catch(() => {
        this.showAlertMessage(`${this.action.name}  Exit Benefit failed`, 'error')
      }).finally(() => {
        bulkItems = []
        this.loading = false
        this.formData.comments = ''
        this.selected = []
      })

      this.loadPending()
    },
    approve () {
      this.approveLoading = true
      let payload = [{
        id: this.item.id,
        status: 1,
        comments: 'Approved'
      }]
      benefitService.approveTerminalBenefits(payload).then(result => {
        this.showAlertMessage('Exit benefit successfully approved', 'success')
        this.exitBenefits = this.exitBenefits.filter(ex => ex.id !== this.item.id)
      }).catch(() => {
        this.showAlertMessage('Unable to approve Exit benefit, try again later', 'error')
      }).finally(() => {
        this.approveLoading = false
        this.dialog = false
      })
      this.loadPending()
    },
    openRejectModal (item) {
      this.rejectDialog = true
      this.formData.id = item.id
    },
    reject () {
      if (this.selected.length > 0) {
        return this.approveBulkSelection()
      }

      this.rejectLoading = true
      const data = {
        status: 2,
        comments: this.formData.comments,
        id: this.formData.id
      }
      benefitService.approveTerminalBenefits(data).then(result => {
        this.showAlertMessage('Exit benefit rejected', 'success')
        this.exitBenefits = this.exitBenefits.filter(ex => ex.id !== data.id)
      }).catch(() => {
        this.showAlertMessage('Unable to reject Exit benefit, try again later', 'error')
      }).finally(() => {
        this.rejectLoading = false
        this.rejectDialog = false
        this.formData.comments = ''
      })
    },
    loadPending () {
      benefitService.getPendingTerminalBenefits().then(res => {
        this.exitBenefits = res.data
      })
    }
  },
  mounted () {
    this.loadPending()
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.iconAvatar {
  width: 122px;
}
.text-caption {
  font-weight: 600;
  font-size: 14px !important
}
.personal-name {
  text-align: left;
  font-weight: 600;
  font-size: 14px !important
}
.action-select {
 padding-top: 24px;
}
</style>
